<template>
  <div>
    <core-modal-header>Select Video Preview Thumbnail</core-modal-header>

    <core-modal-body class="relative">
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-3 max-h-[500px] overflow-y-auto scrollbar-light -mr-4 pr-2">
        <img
          v-for="(thumb, key) in thumbs"
          @click="setThumbnail(key)"
          :src="thumb"
          :key="key"
          role="button"
          class="transition-all w-full rounded-lg opacity-80 hover:opacity-100"
          alt=""
        >
      </div>
      <div v-if="isSaving" class="flex-center absolute inset-0 bg-white/90">
        <core-contextual-loading-box loading-text="Saving Thumbnail" size="sm" />
      </div>
    </core-modal-body>

    <core-modal-footer>
      <u-button @click="modal.close()" variant="soft" color="charcoal">Cancel</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const THUMB_PREVIEW_WIDTH = 300;
  const THUMB_COUNT = 20;

  const emit = defineEmits(['success']);
  const props = defineProps({
    file: Object,
    modal: Object
  });

  const thumbs = computed(() =>
    arrayReduceByAverage([...Array(props.file.screen_caps_count).keys()].map(x => x + 1), THUMB_COUNT)
      .reduce((map, i) => {
        const paddedInd = `${(new Array(5 - String(i).length)).fill('0').join('')}${i}`;

        map[paddedInd] = props.file.images.preview
            .replace(/(.*screencap-)(\d{5})(\.jpg.*)/, `$1${paddedInd}$3`)
            .replace(/(&width=)(\d*)/, `$1${THUMB_PREVIEW_WIDTH}`);

        return map;
      },
      {}
    )
  );

  const isSaving = ref(false);
  async function setThumbnail(thumbKey) {
    isSaving.value = true;
    try {
      const currentFileUrl = await useFileApi().getFileUrl({fileId: props.file.id});
      const updatedFile = await useFilesStore().updateFile({
        id: props.file.id,
        updates: {
          file_url: currentFileUrl
            .split('?')[0]
            .replace(/(screencap-)(\d+)(.jpg)/, `$1${thumbKey}$3`)
        }
      });

      emit('success', {updatedFile});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
