<template>
  <div
    v-if="isDisplayed"
    class="text-gray-500 text-xs text-right"
    :class="{'text-red-300': isAtLimit}">
    {{length}}/{{limit}} characters
  </div>
</template>

<script setup>
  const props = defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      required: true
    },
    displayAt: {
      type: Number,
      default: 0
    }
  });

  const length = computed(() => props.modelValue?.length);
  const isDisplayed = computed(() => length.value >= props.displayAt);
  const isAtLimit = computed(() => length.value >= props.limit);
</script>
