import {makeFilesStore} from "~/stores/files.js";

let uploaderModal = null;

export function useUploadActions() {
  return {

    //todo: revisit this and pass context and context type?
    openUploaderModal({
      size = 'xl',
      acceptedFileTypes,
      album,
      tag,
      person,
      userId,
      shareToken,
      filesStoreId,
      contextType,
      contextId
    } = {}) {

      return new Promise((resolve) => {
        if (isFacebookAndroidWebview()) {
          useFModal({
            id: 'uploader',
            component: (process.client ? defineAsyncComponent(() => import('~/components/upload/unable-to-upload-modal.vue')) : {}), //UploadUnableToUploadModal,
          }).open();

        } else {

          uploaderModal = useFModal({
            id: 'uploader',
            size,
            showClose: false,
            escToClose: false,
            clickToClose: false,
            component: (process.client ? defineAsyncComponent(() => import('~/components/upload/file-import-modal.vue')) : {}), // UploadFileImportModal,
            props: {
              acceptedFileTypes, //todo: test when null
              album,
              tag,
              person,
              shareToken
            },
            eventHandlers: {
              close({hasUploads}) {
                if (hasUploads) {
                  const filesStore = filesStoreId
                    ? makeFilesStore(filesStoreId)()
                    : useFilesStore();

                  filesStore.getFiles({reload: true});

                  if (contextId && contextType) {
                    filesStore.updateContext({contextId, contextType, userId, shareToken});
                  }
                }

                uploaderModal.close();
                uploaderModal = null;
                resolve();
              }
            }
          });
          uploaderModal.open();

        }
      });
    },
    closeUploaderModal() {
      if (uploaderModal) {
        uploaderModal.close();
        uploaderModal = null;
      }
    },

    /*openCollaborationStorageFullModal(container) {
      fModal({
        id: 'collaboration-storage-full',
        headerText: `Contribute to ${container.name}`,
        buttonOkHide: true,
        template: `<p>Oops! Looks like contributing is unavailable because this account is full. ${container.user.name} will need to add more storage before you can contribute to this album.</p>`
      }).open();
    }*/
  }
}
