const apiPaths = {
  groups: '/users/:userId/permission_groups', //get post
  group: '/users/:userId/permission_groups/:groupId', //get put delete
  groupUsers: '/users/:userId/permission_groups/:groupId/users', // get
  //groupUsersCreate: '/users/:userId/permission_groups/:groupId/users/create_multiple', //post
  //groupUsersDelete: '/users/:userId/permission_groups/:groupId/users/destroy_multiple', //post
  groupUsersOverwrite: '/users/:userId/permission_groups/:groupId/users/overwrite', //post

  groupAlbums: '/users/:userId/permission_groups/:groupId/albums', //get
  groupAlbumsOverwrite: '/users/:userId/permission_groups/:groupId/albums/overwrite', //post

  albumGroups: '/users/:userId/albums/:albumId/permission_groups', //get
  //albumGroupsCreate: '/users/:userId/albums/:albumId/permission_groups/create_multiple', //post
  //albumGroupsDelete: '/users/:userId/albums/:albumId/permission_groups/destroy_multiple', //post
  //albumGroupsOverwrite: '/users/:userId/albums/:albumId/permission_groups/overwrite', //post

  //connections: '/users/:userId/friendships'
  userGroups: '/users/:userId/users/:connectionId/permission_groups',
  userGroupsOverwrite: '/users/:userId/users/:connectionId/permission_groups/overwrite' //post
};

export function useGroupsApi() {
  const userId = useSessionStore().currentUserId;

  return {

    fetchGroups: async () => {
      const groups = await fetchAllRecords('permission_groups', cursor => useMarsApiV3().fetch(
        apiPaths.groups,
        {
          params: {
            route: {userId},
            query: {cursor}
          }
        }
      ));

      return groups.map(group => groupMapper(group));
    },


    createGroup: async group => {
      const res = await useMarsApiV3().fetch(
        apiPaths.groups,
        {
          method: 'POST',
          body: {permission_group: group},
          params: {
            route: {userId}
          }
        }
      );

      return groupMapper(res.permission_group);
    },


    updateGroup: async group => {
      const res = await useMarsApiV3().fetch(
        apiPaths.group,
        {
          method: 'PUT',
          body: {permission_group: group},
          params: {
            route: {
              userId,
              groupId: group.id
            }
          }
        }
      )

      return groupMapper(res.permission_group);
    },


    deleteGroup: async groupId => {
      return await useMarsApiV3().fetch(
        apiPaths.group,
        {
          method: 'DELETE',
          params: {
            route: {
              groupId,
              userId
            }
          }
        }
      );
    },


    saveGroupUsers: async({group, userIds}) => {
      return await useMarsApiV3().fetch(
        apiPaths.groupUsersOverwrite,
        {
          method: 'POST',
          body: {user_ids: userIds},
          params: {
            route: {
              userId,
              groupId: group.id,
            }
          }
        }
      );
    },


    fetchGroupUsers: async group => {
      return await fetchAllRecords('users', cursor => useMarsApiV3().fetch(
        apiPaths.groupUsers,
        {
          params: {
            route: {
              userId,
              groupId: group.id
            },
            query: {cursor}
          }
        }
      ));
    },

    fetchUserGroups: async connectionId => {
      const groups = await fetchAllRecords('permission_groups', cursor => useMarsApiV3().fetch(
        apiPaths.userGroups,
        {
          params: {
            route: {
              connectionId,
              userId
            },
            query: {cursor}
          }
        }
      ));

      return groups.map(groupMapper);
    },

    overwriteUserGroups: async ({user, groups}) => {
      return await useMarsApiV3().fetch(
        apiPaths.userGroupsOverwrite,
        {
          method: 'POST',
          body: {permission_group_ids: groups.map(g => g.id)},
          params: {
            route: {
              userId,
              connectionId: user.id
            }
          }
        }
      );
    },

    getAlbumPermissionGroups: async ({albumId}) => {
      const groups = await fetchAllRecords('permission_groups', cursor => useMarsApiV3().fetch(
        apiPaths.albumGroups,
        {
          params: {
            route: {albumId, userId},
            query: {cursor}
          }
        }
      ));

      return groups.map(groupMapper);
    },

    fetchGroupAlbums: async groupId => {
      const albums =  await fetchAllRecords('albums', cursor => useMarsApiV3().fetch(
        apiPaths.groupAlbums,
        {
          params: {
            route: {
              userId,
              groupId: groupId
            },
            query: {cursor}
          }
        }
      ));

      return albums.map(albumMapper);
    },

    overwriteGroupAlbums: async ({group, albumIds}) => {
      return await useMarsApiV3().fetch(
        apiPaths.groupAlbumsOverwrite,
        {
          method: 'POST',
          body: {album_ids: albumIds},
          params: {
            route: {
              userId,
              groupId: group.id
            }
          }
        }
      );
    }
  }
}
