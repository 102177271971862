<template>
  <section>
    <core-modal-header>Add People</core-modal-header>

    <core-modal-body>

      <p class="mb-3">
        <template v-if="isMulti">Add the following people to the <span class="italic font-semibold">the {{count}} selected files</span>...</template>
        <template v-else>Add the following people to the file <span class="italic font-semibold">{{name}}</span>...</template>
      </p>

      <u-form-group>
        <people-select-menu autofocus v-model="people" />
      </u-form-group>

    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
      <u-button :loading="isBusy" @click="save">Add People</u-button>
    </core-modal-footer>

  </section>
</template>

<script setup>

  const emit = defineEmits(['success'])
  const props = defineProps({
    files: Array,
    modal: Object
  });

  const filesStore = useFilesStore();
  const people = ref([]);
  const count = computed(() => props.files.length);
  const isMulti = computed(() => count.value > 1);
  const name = computed(() => props.files[0].name);
  const isBusy = ref(false);

  async function  save() {
    try {
      isBusy.value = true;

      const fileIds = props.files.map(file => file.id);

      await useFileApi().updateFiles({ids: fileIds, updates: {personIds: people.value.map(p => p.id)}});
      filesStore.invalidateFileDetails();

      useSuccessToast().add({
        id: 'files-tagged-success',
        description: isMulti.value
          ? `The ${count.value} selected files were tagged.`
          : `The file ${name.value} was tagged.`
      });

      emit('success');

    } catch (e) {
      useErrorToast().add();
    } finally {
      isBusy.value = false;
    }
  }

  function close() {
    props.modal.close();
  }

</script>
