<template>
  <core-price-display :base-price="basePrice" :discounted-price="price" free-text="" />
</template>

<script setup>
  const props = defineProps({
    basePrice: {
      type: Number,
      required: true
    },
    productId: [String, Number],
    promotion: Object
  });

  const finalPromotion = computed(() => props.promotion || usePricingStore().promotions?.find(p => p.id === props.productId)?.primary_promotion);
  const price = computed(() => (finalPromotion.value ? props.basePrice - Math.floor(props.basePrice * (finalPromotion.value.percent_off / 100)) : props.basePrice));
</script>
