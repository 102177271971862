import {storeToRefs} from 'pinia';

function isFileAllowed(file) {
  return SLIDESHOW_SUPPORTED_FILE_TYPES.some(type => file.is[type]);
}

function canMakeSlideshow(container) {
  if (container.files_count && container.audios_count !== container.files_count) {
    return true;
  }
}

export function useSlideshow({container, fileContext, userId, shareToken}) {
  const eventBus = useEventBus('core-loading-set');

  return {
    async start() {
      const filesStore = useFilesStore();
      const {files, hasMore} = storeToRefs(filesStore);
      const isLoading = ref(false);

      const prepareFiles = async () => {
        //get detail record for video, project, and document files
        const needDetails = file => (file.is.video || file.is.project || file.is.document);

        return filesStore.getFileDetails({
          files: files.value.filter(f => isFileAllowed(f) && needDetails(f)),
          contextId: container.id,
          contextType: fileContext,
          userId,
          shareToken
        });
      };

      if (!canMakeSlideshow(container)) {
        useInfoToast().add({
          id: 'no-slideshow',
          description: 'No files suitable for a slideshow.'
        });
        return;
      }

      if (!files.value.filter(isFileAllowed).length) {
        useInfoToast().add({
          id: 'no-slideshow',
          description: `We couldn't start a slideshow.`
        });
        return;
      }

      eventBus.emit({
        isLoading: true,
        message: 'Preparing Slideshow'
      });

      try {
        await prepareFiles();

        useFModal({
          id: 'slideshow',
          component: (process.client ? defineAsyncComponent(() => import('~/components/slideshow/player.vue')) : {}), //SlideshowPlayer,
          props: {
            files: computed(() => files.value.filter(isFileAllowed)),
            hasMoreFiles: hasMore
          },
          size: 'fullscreen',
          eventHandlers: {
            async loadMore() {
              if (!hasMore.value || isLoading.value) {
                return;
              }

              try {

                isLoading.value = true;
                await filesStore.getFiles({nextPage: true});
                await prepareFiles();

              } catch (e) {
                //nothing to do
              } finally {
                isLoading.value = false;
              }
            }
          }
        }).open();

      } catch (e) {
        eventBus.emit({
          isLoading: true,
          message: 'Something went wrong. Please try again later.'
        });
        await waitFor(2000);
      } finally {
        eventBus.emit({isLoading: false});
      }
    }
  }
}
