<template>
  <div>
    <core-modal-header>
      <template v-if="inContext">Remove File{{files.length > 1 ? 's' : null}}?</template>
      <template v-else>Move to Delete Bin?</template>
    </core-modal-header>

    <core-modal-body>
      <p v-html="mainText" />

      <u-checkbox
        v-if="inContext && canUserDelete"
        v-model="deleteFromLibrary"
        label="Also move to Delete Bin"
        class="mt-4"
      />

      <u-alert
        v-if="removeEverywhere"
        color="heroblue"
        :icon="COMMON_ICONS.information"
        :title="`Items will remain in your Delete Bin for ${period} days.`"
        class="mt-4"
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
      <u-button @click="confirm" :loading="isSaving">
        <template v-if="removeEverywhere">Move to Delete Bin</template>
        <template v-else>Remove</template>
      </u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);

  const props = defineProps({
    files: {
      type: Array,
      default: () => []
    },
    context: Object,
    contextType: String,
    modal: Object
  });

  const isSaving = ref(false);
  const deleteFromLibrary = ref(false);
  const {canUserDelete} = useUserContext({entity: props.context});
  const {period} = useSessionStore().deleteBinSummary;

  const count = computed(() => props.files.length);
  const isMulti = computed(() => count.value > 1,);
  const inContext = computed(() => Boolean(props.context && props.contextType));
  const fileName = computed(() => count.value && html2text(props.files[0].name));
  const contextName = computed(() => {
    const fallback = props.contextType === FILE_CONTEXTS.person ? 'this person' : 'this container';
    return html2text(props.context?.name || fallback)
  });

  const removeEverywhere = computed(() => !inContext.value || deleteFromLibrary.value);

  const mainText = computed(() => {
    const theCount = `<strong><em>the ${count.value} selected files</em></strong>`;
    const theFileName = `<strong><em>${fileName.value}</em></strong>`;
    const theContainerName = `<strong><em>${contextName.value}</em></strong>`;

    // in container
    if (inContext.value) {
      return isMulti.value
        ? `Remove ${theCount} from ${theContainerName}?`
        : `Remove ${theFileName} from ${theContainerName}?`;
    }

    // from gallery
    return isMulti.value
      ? `Move ${theCount} to your Delete Bin?`
      : `Move ${theFileName} to your Delete Bin?`;
  });

  function close() {
    props.modal.close();
  }

  async function confirm() {
    if (isSaving.value) return;

    try {
      let toastMessage;

      isSaving.value = true;

      if (removeEverywhere.value) {
        await useFilesStore().removeFilesFromLibrary({ids: props.files.map(f => f.id)});

        toastMessage = isMulti.value
            ? `The ${count.value} selected files were moved to your Delete Bin.`
            : `The file ${fileName.value} was moved to your Delete Bin.`

      } else if (inContext.value) {
        const ids = props.files.map(f => f.id);

        await useFilesStore().removeFilesFromContext({
          ids,
          contextType: props.contextType,
          contextId: props.context.id
        });

        toastMessage = isMulti.value
            ? `The ${count.value} selected files were removed from ${contextName.value}.`
            : `The file ${fileName.value} was removed from ${contextName.value}.`
      }

      useSuccessToast().add({
        id: 'file-delete-success',
        description: toastMessage
      });

      emit('success');
    } catch (e) {
      useErrorToast().add();
      close();
    }
  }
</script>
