<template>
  <div class="flex gap-2 sm:gap-4">
    <ul class="list-none text-base grid grid-cols-2 gap-1 sm:gap-2">
      <li
        v-for="month in months"
        class="flex-center cursor-pointer px-4 py-2 transition-colors rounded border border-gray-200 shadow-sm leading-none text-sm"
        :class="[
          {'bg-heroblue text-white font-semibold': month === selection.month},
          {'hover:bg-gray/10': month !== selection.month}
        ]"
        @click="setMonth(month)"
      >
        <span>{{month.name}}</span>
      </li>
    </ul>

    <u-divider orientation="vertical" />

    <ul class="list-none grid grid-cols-1 gap-1 sm:gap-2 overflow-y-auto max-h-56 relative text-center scrollbar-light pr-2 -mr-2" ref="yearPickerEl">
      <li
        v-for="year in years"
        class="cursor-pointer px-6 py-3 transition-colors rounded border border-gray-200 shadow-sm leading-none text-sm"
        :class="[
          {'bg-heroblue text-white font-semibold': year === selection.year},
          {'hover:bg-gray/10': year !== selection.year}
        ]"
        @click="setYear(year)"
        :data-year-value="year"
      >
        {{year}}
      </li>
    </ul>
  </div>
</template>

<script setup>

  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1500, useDayjs().utc().year()]
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const months = dayJs[dayJs.locale()].months.map((month, index) => ({
    name: month,
    index
  }));
  const years = Array.from(Array((props.yearRange[1] - props.yearRange[0]) + 1), (n, i) => props.yearRange[1] - i);
  const selection = computed({
    get() {
      const theDate = dateRange.value?.val ? dayJs(dateRange.value?.val[0]) : dayJs();

      return {
        month: months.find(month => month.index === theDate.utc().month()),
        year: theDate.utc().year()
      }
    },
    set({month, year}) {
      dateRange.value.val = [
        dayJs().utc().year(year).month(month.index).startOf('month'),
        dayJs().utc().year(year).month(month.index).endOf('month')
      ];
    }
  });

  function setMonth(month) {
    selection.value = {
      month,
      year: selection.value.year
    };
  }

  function setYear(year) {
    selection.value = {
      month: selection.value.month,
      year
    };
  }

  const yearPickerEl = ref();

  onMounted(() => {
    //note: needed to update the dateRange model to the appropriate range
    selection.value = {
      month: selection.value.month,
      year: selection.value.year
    };

    const {y} = useScroll(yearPickerEl.value, { behavior: 'smooth' });
    const itemEl = yearPickerEl.value.querySelector(`[data-year-value="${selection.value.year}"]`);

    y.value += itemEl.offsetTop - 100;
  });

</script>
