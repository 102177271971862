import {
  FileExifDownloadModal,
  FileBulkOrganizeModal,
  FileAddTagsModal,
  FileAddPeopleModal,
  FileAddMoveToAlbumModal,
  FileDeleteModal,
  FilePurgeModal,
  FileCopyToLibraryModal,
  CoreShareModal,
  FileVideoOptimizeModal,
  FileVideoThumbnailModal,
  FileAddFilesToContextModal,
  LazyFilePhotoEditor
} from '#components';

export function useFileActions() {
  async function downloadWithExifPrompt(downloadables) {
    const preferences = usePreferencesStore();
    const downloadWithExif = () => preferences.currentUserPreferences[CURRENT_USER_PREFERENCES.exifDownloadsEnabled];
    const shouldPrompt = preferences.currentUserPreferences[CURRENT_USER_PREFERENCES.exifDownloadsPrompt];
    const areSomeWritable = !downloadables || downloadables.some(item => item.is.downloadableWithExif); //if downloadables is not passed, it indicates a container download, so just display the default modal

    if (downloadWithExif() && shouldPrompt) {
      return new Promise(resolve => {
        const promptModal = useFModal({
          id: 'exif-export-modal',
          component: FileExifDownloadModal,
          props: {
            hasWritables: areSomeWritable,
            hasMultiple:  (!areSomeWritable && downloadables.length > 1)
          },
          eventHandlers: {
            yes() {
              promptModal.close();
              resolve(true);
            },
            no() {
              promptModal.close();
              resolve(false);
            }
          }
        });
        promptModal.open();
      });
    }

    return downloadWithExif();
  }

  function downloadSuccessToast({withExif, undownloadables = []}) {
    let description;
    let unavailable = '';

    if (undownloadables.length) {
      unavailable = `${undownloadables.length} file(s) could not be downloaded because they are still processing.`;
    }

    description = withExif
      ? `Download with metadata request received. You'll be notified when it's ready. ${unavailable}`
      : `Download will begin momentarily. ${unavailable}`;

    useSuccessToast().add({
      id: 'file-download-success',
      description,
      actions: withExif ? [
        {
          label: 'View Notifications',
          click: () => navigateTo('/notifications')
        }
      ] : []
    });
  }

  return {
    selectionToolbarActions: {
      organize: 'organize',
      moveToAlbum: 'moveToAlbum',
      share: 'share',
      download: 'download',
      delete: 'delete',
      removeFromContainer: 'removeFromContainer',
      copyToMyGallery: 'copyToMyGallery',
      makeCover: 'makeCover',
      restoreToLibrary: 'restoreToLibrary',
      purge: 'purge'
    },

    addMoveFilesToAlbum({files, fromAlbumId}) {
      const filesArr = Array.isArray(files) ? files : [files];

      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'add-files-to-album',
          component: FileAddMoveToAlbumModal,
          props: {
            files: filesArr,
            fromAlbumId
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    addTagsToFiles({files}) {
      const filesArr = Array.isArray(files) ? files : [files];

      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'add-tags-to-files-modal',
          clickToClose: false,
          component: FileAddTagsModal,
          overflowClass: 'overflow-none',
          props: {
            files: filesArr
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    addFilesToPeople({files}) {
      const filesArr = Array.isArray(files) ? files : [files];

      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'add-tags-to-files-modal',
          clickToClose: false,
          component: FileAddPeopleModal,
          overflowClass: 'overflow-none',
          props: {
            files: filesArr
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    bulkOrganize({files}) {
      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'bulk-organize-modal',
          clickToClose: false,
          component: FileBulkOrganizeModal,
          overflowClass: 'overflow-none',
          props: {
            files
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    addFilesToContext({context, contextType, userId, shareToken = ''}) {
      const modal = useFModal({
        id: 'add-files-to-context',
        component: FileAddFilesToContextModal,
        size: 'fullscreen',
        showClose: false,
        props: {
          context,
          contextType,
          userId,
          shareToken
        }
      });

      modal.open();
    },

    /**
     * Delete files from the system
     * @param files
     * @param context
     * @param contextType
     * @returns {Promise<void>}
     */
    async deleteFiles({files, context, contextType}) {
      let filesArr;
      if (files) {
        filesArr = (Array.isArray(files) ? files : [files]);
      }

      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'file-delete-modal',
          clickToClose: false,
          component: FileDeleteModal,
          props: {
            files: filesArr,
            context,
            contextType
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    /**
     * Purge files from the system
     * @param files
     * @param deleteAllPermanently
     * @returns {Promise<void>}
     */
    async purgeFiles({files, deleteAllPermanently}) {
      return new Promise(async (resolve) => {
        const modal = await useFModal({
          id: 'file-purge-modal',
          clickToClose: false,
          component: FilePurgeModal,
          props: {
            files,
            deleteAllPermanently
          },
          eventHandlers: {
            success() {
              modal.close();
              resolve();
            }
          }
        }).open();
      });
    },

    async restoreFiles({files}) {
      try {
        await useFilesStore().restoreFiles({ids: files.map(f => f.id)});
      } catch (e) {
        useErrorToast().add();
      }
    },

    /**
     * Share files or containers
     * @param files
     * @returns {Promise<void>}
     */
    async share({files}) {
      let shareType = 'file';
      let shareObject;

      if (files && Array.isArray(files)) {
        shareType = 'container';
        shareObject = await useContainerApi().createContainer({fileIds: files.map(f => f.id)});
      } else {
        shareObject = files;
      }

      // let isProject;
      /*if (files?.is?.project) {
        isProject = true;
      }*/ //todo: follow up after decision on projects

      useFModal({
        id: 'share-files',
        component: CoreShareModal,
        props: {
          shareObject,
          shareType
        }
      }).open();
    },

    async copyFilesToCurrentUserLibrary({files, userId}) {
      const filesArr = Array.isArray(files) ? files : [files];

      //arrays for storing copyable and uncopyable files
      const uncopyables = [];
      const copyables = [];

      //roll over passed files and determine copyable vs uncopyable
      filesArr.forEach(f => (f.is.copyableFromUser ? copyables.push(f) : uncopyables.push(f)));

      //toast message for uncopyables
      const uncopyableToast = () => {
        useWarningToast().add({
          id: 'copy-files-uncopyable',
          description: 'Unable to copy some or all of the files to your Gallery at this time.'
        });
      };

      //they are all uncopyable, display a toast and resolve
      if (uncopyables.length === filesArr.length) {
        uncopyableToast();
        return;
      }

      return new Promise(resolve => {
        const modal = useFModal({
          id: 'copy-files-to-library',
          component: FileCopyToLibraryModal,
          props: {
            files: copyables,
            userId
          },
          eventHandlers: {
            success() {
              if (uncopyables.length) {
                uncopyableToast();
              }

              modal.close();
              resolve();
            }
          }
        });

        modal.open();
      });
    },

    async downloadFiles({files, userId}) {
      const filesArr = Array.isArray(files) ? files : [files];

      //arrays for storing copyable and uncopyable files
      const undownloadables = [];
      const downloadables = [];

      //roll over passed files and determine copyable vs uncopyable
      filesArr.forEach(f => {
        if (f.is.downloadable) {
          downloadables.push(f);
        } else {
          undownloadables.push(f);
        }
      });

      //map the ids of copyable items to an easy to use array
      const downloadableFileIds = downloadables.map(f => f.forever_file_id || f.id);

      //no downloadables, toast and return promise
      if (!downloadables.length) {
        useWarningToast().add({
          id: 'files-not-downloadable',
          description: 'The files could not be downloaded at this time.'
        });
        return Promise.resolve();
      }

      //check withExif
      const withExif = await downloadWithExifPrompt(downloadables);

      //download and toast
      await useFileApi().downloadFiles({ids: downloadableFileIds, userId, withExif});

      downloadSuccessToast({withExif, undownloadables});
    },

    async downloadContext({context, contextType, userId}) {
      const withExif = await downloadWithExifPrompt();

      try {
        await useFileApi().downloadContext({context, contextType, userId, withExif});
        downloadSuccessToast({withExif});
      } catch (e) {
        useErrorToast().add();
      }

    },

    async toggleFavorite({file}) {
      try {
        return useFilesStore().updateFile({id: file.id, updates: {favorite: !file.favorite}});
      } catch (e) {
        useErrorToast().add();
      }
    },

    optimizeVideo({file}) {
      return new Promise(resolve => {
        const modal = useFModal({
          id: 'optimize-video',
          component: FileVideoOptimizeModal,
          props: {
            file
          },
          eventHandlers: {
            success({optimized}) {
              resolve(optimized);
              modal.close();
            }
          }
        });
        modal.open();
      });
    },

    setVideoThumbnail({file}) {
      return new Promise(resolve => {
        const modal = useFModal({
          id: 'set-video-thumbnail',
          component: FileVideoThumbnailModal,
          size: 'lg',
          props: {
            file
          },
          eventHandlers: {
            success({updatedFile}) {
              resolve(updatedFile);
              modal.close();
            }
          }
        });
        modal.open();
      });
    },

    async setFileAsProfilePhoto({file}) {
      try {
        const updatedUser = await useSessionStore().updateCurrentUser({
          forever_file_id: file.id
        });

        if (updatedUser) {
          await useFilesStore().refreshFileDetails({updatedUser});
        }
      } catch (e) {
        useErrorToast().add();
      }
    },

    async setFileAsAlbumCover({file, album}) {
      try {
        const updatedAlbum = await useAlbumsStore().updateAlbum({
          id: album.id,
          updates: {
            cover_file_id: file.id
          }
        });

        if (updatedAlbum) {
          await useFilesStore().refreshFileDetails({updatedAlbum});
        }
      } catch (e) {
        useErrorToast().add();
      }
    },

    async setFileAsPersonCover({file, person}) {
      try {
        const updatedPerson = await usePeopleStore().updatePerson({
          id: person.id,
          updates: {
            forever_file_id: file.id
          }
        });

        if (updatedPerson) {
          await useFilesStore().refreshFileDetails({updatedPerson});
        }
      } catch (e) {
        useErrorToast().add();
      }
    },

    async setFileAsTagCover({file, tag}) {
      try {
        const updatedTag = await useTagsStore().updateTag({
          id: tag.id,
          updates: {
            forever_file_id: file.id
          }
        });

        if (updatedTag) {
          await useFilesStore().refreshFileDetails({updatedTag});
        }
      } catch (e) {
        useErrorToast().add();
      }
    },

    /**
     * Starts the photo editor and handles export from it
     * @param file The file to edit
     * @param options Album or tag id
     * @returns {Promise}
     */
    async editImage({file, contextType, contextId, busyIndicator = {}}) { //todo: implement busy indicator
      const useOriginal = file.file_size < FILE_EDIT_ORIGINAL_SIZE_THRESHOLD * 1024 * 1024;

      if (!useOriginal) {
        const confirmEdit = await new Promise(resolve => {
          useConfirmModal({
            heading: `Edit this File?`,
            message: `Images over ${FILE_EDIT_ORIGINAL_SIZE_THRESHOLD}MB will be compressed, saved as a .jpg, and will not retain original file metadata when edited. Continue?`,
            confirmMethod: () => resolve(true)
          }).open();
        });

        if (!confirmEdit) {
          return;
        }
      }

      useFModal({
        id: 'edit-image',
        component: LazyFilePhotoEditor,
        size: 'fullscreen',
        showClose: false,
        bgClass: 'transparent',
        props: {
          file,
          useOriginal,
          contextType,
          contextId
        }
      }).open();
    }
  }
}
