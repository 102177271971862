<!--todo: verfiy when we integrate this-->
<template>
  <div>
    <core-modal-header>
      <template v-if="deleteAllPermanently">Empty Delete Bin?</template>
      <template v-else>Delete Permanently?</template>
    </core-modal-header>

    <core-modal-body>
      <p>
        <template v-if="deleteAllPermanently">Are you sure you want to empty your Delete Bin?</template>
        <template v-else>Delete the selected file(s) permanently?</template>
      </p>
      <u-alert
        color="red"
        icon="i-ri-error-warning-line"
        title="This action cannot be undone."
        class="mt-4"
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
      <u-button @click="confirm" :loading="isSaving">
        <template v-if="deleteAllPermanently">Empty Delete Bin</template>
        <template v-else>Delete Permanently</template>
      </u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);

  const props = defineProps({
    files: {
      type: Array,
      default: () => []
    },
    deleteAllPermanently: Boolean,
    modal: Object
  });

  const isSaving = ref(false);

  function close() {
    props.modal.close();
  }

  async function confirm() {
    try {
      isSaving.value = true;
      let purgeSuccessToast;

      if (props.deleteAllPermanently) {
        await useFileApi().purgeAllDeletedFiles();
        useFilesStore().clearFiles();
        purgeSuccessToast = 'Your Delete Bin was emptied.';
      } else {
        await useFilesStore().purgeFiles({ids: props.files.map(f => f.id)})
        purgeSuccessToast = 'The selected file(s) were deleted permanently.';
      }
      useSessionStore().getDeleteBinSummary();

      useSuccessToast().add({
        id: 'file-purge-success',
        description: purgeSuccessToast
      });

      emit('success');

    } catch (e) {
      useErrorToast().add();
      close();
    } finally {
      isSaving.value = false;
    }
  }
</script>
