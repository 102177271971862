<template>
  <div class="album-delete-modal">
    <core-modal-header>Delete Album{{isMulti ? 's' : ''}}?</core-modal-header>

    <core-modal-body>
      <p>
        <template v-if="isMulti">Delete the {{count}} selected albums?</template>
        <template v-else>Delete the album <strong>{{albumName}}</strong>?</template>
      </p>

      <u-alert
        icon="i-ri-error-warning-line"
        class="mt-4"
        color="red"
        title="This action cannot be undone."
        description="Nested albums will also be deleted. Files will not be affected and will remain in your Gallery."
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="confirm" :loading="isSaving">Yes, Delete</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    albums: Array,
    modal: Object
  });

  const count = props.albums.length;
  const isMulti = count > 1;
  const albumName = html2text(props.albums[0].name);
  const isSaving = ref(false);

  async function confirm() {
    try {
      isSaving.value = true;
      await useAlbumsStore().destroyAlbums({ids: props.albums.map(a => a.id)});
      emit('success');
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
