const apiPaths = {
  tag: '/users/:userId/tags/:id', //get, put, delete
  tags: '/users/:userId/tags', //get, post
  destroyTags: '/users/:userId/tags/destroy_multiple',
  disableShareToken: '/users/:userId/tags/:id/disable_share_token', //post
  resetShareToken: '/users/:userId/tags/:id/reset_share_token' //post
};

export function useTagApi() {
  return {
    async getTags({cursor, search, order, userId = useSessionStore().currentUserId}) {
      const data = await useMarsApiV3().fetch(apiPaths.tags, {
        params: {
          route: {userId},
          query: {cursor, search, order}
        }
      });

      data.tags = data.tags.map(tagMapper);
      return data;
    },

    async createTag({name}) {
      const data = await useMarsApiV3().post(apiPaths.tags, {
        params: {
          route: {userId: useSessionStore().currentUserId}
        },
        body: {name}
      });

      return tagMapper(data.tag);
    },

    async getTag({id, userId = useSessionStore().currentUserId, shareToken}) {
      const data = await useMarsApiV3().fetch(apiPaths.tag, {
        params: {
          route: {userId, id},
          query: {share_token: shareToken}
        }
      });

      return tagMapper(data.tag);
    },

    async updateTag({id, payload}) {
      const data = await useMarsApiV3().put(apiPaths.tag, {
        params: {
          route: {
            userId: useSessionStore().currentUserId,
            id
          }
        },
        body: payload
      });

      return tagMapper(data.tag);
    },

    async destroyTags({ids}) {
      return await useMarsApiV3().post(apiPaths.destroyTags, {
        params: {route: {userId: useSessionStore().currentUserId}},
        body: {ids}
      });
    },

    async disableShareToken({id}) {
      return await useMarsApiV3().post(apiPaths.disableShareToken, {
        params: {
          route: {
            id,
            userId: useSessionStore().currentUserId
          }
        }
      });
    },

    async resetShareToken({id}) {
      return await useMarsApiV3().post(apiPaths.resetShareToken, {
        params: {
          route:{
            id,
            userId: useSessionStore().currentUserId
          }
        }
      });
    }
  };
}
