<template>
  <div class="h-full flex flex-col">
    <u-form-group v-if="isRoot" label="Privacy Settings" size="xl">
      <ul class="list-unstyled">
        <li v-for="permissionLayer in permissionLayers" :key="permissionLayer.key" class="py-2 first:border-0 ">
          <div class="flex">
            <label class="flex items-center flex-1 gap-3 text-sm">
              <u-radio
                  class="flex-shrink-0"
                  name="permission-selection"
                  :value="permissionLayer.key"
                  @input="choosePermissionLayer(permissionLayer)"
                  :checked="permissionLayer === currentPermissionLayer"
              />

              <span class="flex gap-2 leading-none">
              <span class="font-semibold text-charcoal">{{permissionLayer.label}}</span>
              <span class="text-gray-500">({{permissionLayer.help}})</span>
            </span>
            </label>

            <u-button
                v-if="permissionLayer === groupPermissionLayer && album.privacy === groupPermissionLayer.key"
                variant="soft"
                size="2xs"
                @click="toggleEditGroups"
            >
              {{isEditingGroups ? 'Done': 'Edit'}}
            </u-button>
          </div>

          <div v-if="permissionLayer === groupPermissionLayer" v-show="album.privacy === groupPermissionLayer.key" class="mt-2">
            <core-contextual-loading-box v-if="isLoading" key="loading" loading-text="Loading Groups" size="sm" />
            <core-contextual-error-box v-else-if="isErrored" key="errored" :action="loadAlbumPermissionGroups" size="sm" />

            <template v-else>
              <groups-selection-list
                  v-show="isEditingGroups"
                  v-model:selected-groups="albumPermissionGroups"
                  class="h-48"
                  ref="permissionGroupSelectionComp"
              />
              <ul v-show="!isEditingGroups" class="list-unstyled overflow-y-auto max-h-48 scrollbar-light">
                <li v-for="group in albumPermissionGroups" :key="group.id" class="flex items-center gap-2 py-2 px-2 odd:bg-gray-100">
                  <groups-avatar :group="group" size="xs" />
                  <span class="flex-1 text-sm break-long-string">{{group.name}}</span>
                </li>
              </ul>
            </template>
          </div>
        </li>
      </ul>
    </u-form-group>

    <u-alert
      v-else
      color="blue"
      variant="subtle"
      :icon="COMMON_ICONS.information"
      :title="`Current Setting: ${nestedSharedWithText}`"
      description="The privacy settings for this album are inherited from the parent level."
    />
  </div>

</template>

<script setup>
  const addNewToListEvent = useEventBus('core-add-new-to-list-reset');

  const props = defineProps({
    parent: Object
  });

  const album = defineModel('album');
  const permissionLayers = Object.values(ALBUM_PERMISSION_LAYERS).map(pl => Object.assign({}, pl));
  const isEditingGroups = ref(false);
  const isErrored = ref(false);
  const isLoading = ref(false);
  const albumPermissionGroups = ref([]);
  const permissionGroupSelectionComp = ref();

  const currentPermissionLayer = computed(() => permissionLayers.find(l => l.key === album.value.privacy));
  const groupPermissionLayer = permissionLayers.find(pl => pl.key === ALBUM_PERMISSION_LAYERS.group.key);
  const isRoot = !album.value.parent_album && !props.parent;
  const nestedSharedWithText = computed(() => (
    currentPermissionLayer.value.key === groupPermissionLayer.key
      ? `Shared with ${album.value.permission_groups_count ?? props.parent.permission_groups_count} Groups`
      : currentPermissionLayer.value.label
    )
  );

  function updateAlbum({permissionLayer} = {}) {
    const newAlbum = Object.assign({}, album.value, {privacy: permissionLayer?.key || album.value.privacy});

    if (newAlbum.privacy === ALBUM_PERMISSION_LAYERS.group.key) {
      newAlbum.permission_group_ids = albumPermissionGroups.value.map(pl => pl.id);

      if (!albumPermissionGroups.value.length) {
        isEditingGroups.value = true;
      }
    } else {
      delete newAlbum.permission_group_ids;
    }

    album.value = newAlbum;
  }

  function choosePermissionLayer(permissionLayer) {
    updateAlbum({permissionLayer});
  }

  function toggleEditGroups() {
    isEditingGroups.value = !isEditingGroups.value;

    if (!isEditingGroups.value) {
      addNewToListEvent.emit();
    }
  }

  async function loadAlbumPermissionGroups() {
    if (album.value.privacy === groupPermissionLayer.key) {
      isLoading.value = true;
      isErrored.value = false;

      try {
        albumPermissionGroups.value = await useGroupsApi().getAlbumPermissionGroups({albumId: album.value.id});
      } catch (e) {
        isErrored.value = true;
      } finally {
        isLoading.value = false;
      }
    }
  }

  if (isRoot) {
    loadAlbumPermissionGroups();
  }

  watch(albumPermissionGroups, () => updateAlbum({permissionLayer: groupPermissionLayer}))
</script>
