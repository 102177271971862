
export function getFileLastModifiedDate(file) {
  const lastModified = file.lastModified || file.lastModifiedDate || new Date();
  const offset = new Date().getTimezoneOffset();
  return useDayjs()(lastModified).subtract(offset, 'minute').toISOString();
}

export function formatDuration(duration, showHours) {
  if (isNaN(duration)) {
    return;
  }

  //grab the time portion of an ISO date and split
  const roundedDuration = Math.floor(duration / 1000) * 1000;
  const parts = new Date(roundedDuration).toISOString().match(/(\d{2}:){2}\d{2}/)[0].split(':');

  //if no hours
  if (!showHours && parts[0] === '00') {
    parts.shift();

    //trim leading 0 of minutes when no hours
    if (parts[0].startsWith('0')) {
      parts[0] = parts[0].slice(1);
    }
  }

  return parts.join(':');
}

export function getMonths() {
  return useDayjs().localeData().months();
}
