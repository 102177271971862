<template>
  <span class="cui-price-display" :class="stacked ? 'flex flex-col items-center' : ''">
    <template v-if="shouldShowDiscount">
      <span v-html="formattedDiscounted" class="opacity-70 line-through pr-1" :class="smallerDiscount ? 'smaller' : ''" />
    </template>

    <span v-html="formattedPrice" class="price font-semibold" />
  </span>
</template>

<script setup>

  const props = defineProps({
    basePrice: {
      type: Number,
      required: true
    },
    precision: {
      type: Number,
      default: 2
    },
    discountedPrice: {
      type: [Number, null]
    },
    showSuffix: {
      type: Boolean,
      default: true
    },
    freeText: {
      type: String,
      default: 'Free'
    },
    smallerDiscount: {
      type: Boolean
    },
    stacked: {
      type: Boolean
    }
  });

  const shouldShowDiscount = computed(() => (props.discountedPrice || Number(props.discountedPrice) === 0) && Number(props.discountedPrice) !== Number(props.basePrice));
  const formattedPrice = computed(() => formatPrice(shouldShowDiscount.value ? props.discountedPrice : props.basePrice, props.showSuffix, props.precision));
  const formattedDiscounted = computed(() => formatPrice(props.basePrice, false, props.precision));

  function formatPrice(price, showSuffix, precision) {
    return (Number(price) ? formatCurrency(price, {superSuffix: showSuffix, showSuffix, precision}) : props.freeText);
  }

</script>

<style scoped lang="scss">
  .cui-price-display {
    .smaller { font-size: 75%; }
    .price {
      :deep(sup) {
        padding-left: 0.1rem;
        top: unset;
      }
    }
  }
</style>
