<template>
  <div class="relative">

    <transition name="f-fade">
      <div v-if="isCopyingAlbum" class="absolute inset-0 z-10 bg-white/80 flex-center">
        <core-contextual-loading-box loading-text="Copying Album" />
      </div>
    </transition>

    <core-modal-header>Copy Album?</core-modal-header>

    <core-modal-body>

      <core-contextual-loading-box v-if="isLoadingMetrics" size="sm" loading-text="Loading"  />

      <p v-else-if="albumHasTooManyFiles">Unable to copy an album with more than {{ALBUM_COPY_FILES_COUNT_LIMIT}} files in it.</p>

      <core-contextual-error-box v-else-if="hasGenericError" size="sm" />

      <template v-else-if="albumMetrics">
        <p>
          <template v-if="notEnoughStorage">You don't have enough storage to copy all of this album's files.</template>
          <template v-else>Copy the {{albumFileCount}} files in album <strong><em>{{album.name}}</em></strong> to your account?</template>
        </p>

        <ul class="mt-4 text-base">
          <li>Storage Remaining: <strong>{{currentUser.storage_remaining_gb}}</strong></li>
          <li>Storage Needed: <strong>{{albumMetrics.human_bytes}}</strong></li>
        </ul>

        <p class="my-4" v-if="!notEnoughStorage">Are you sure you want to continue?</p>

        <u-meter-group :min="0" :max="progressBarConfig.max" size="lg" class="mt-4" :ui="{list: 'pl-0'}">
          <u-meter
            v-for="item in progressBarConfig.items"
            :value="item.value"
            :color="item.color"
            :label="item.label"
            :icon="item.icon"
          />
        </u-meter-group>
      </template>
    </core-modal-body>

    <core-modal-footer>
      <u-button variant="soft" color="charcoal" @click="modal.close()">Close</u-button>

      <template v-if="!hasGenericError">
        <template v-if="canCopy">
          <u-button variant="outline" @click="copyFiles(false)" :disabled="isCopyingAlbum">Files Only</u-button>
          <u-button @click="copyFiles(true)" :disabled="isCopyingAlbum">Files & Tags</u-button>
        </template>

        <template v-else-if="notEnoughStorage">
          <u-button color="salmon" @click="buyStorage">Buy More Storage</u-button>
        </template>
      </template>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const props = defineProps({
    album: Object,
    modal: Object
  });

  const isLoadingMetrics = ref(false);
  const isCopyingAlbum = ref(false);
  const hasGenericError = ref(false);
  const albumMetrics = ref(null);
  const hasApiStorageError = ref(false);
  const currentUser = useSessionStore().currentUser;
  const albumFileCount = props.album?.files_count;
  const albumHasTooManyFiles = albumFileCount > ALBUM_COPY_FILES_COUNT_LIMIT;
  const albumSizePercentOfStorage = computed(() => Math.ceil((albumMetrics.value.total_bytes / currentUser.storage_capacity) * 100));
  const totalStorageNeeded = computed(() => currentUser.storage_used + albumMetrics.value?.total_bytes);
  const storageDeficit = computed(() => Math.max(totalStorageNeeded.value - currentUser.storage_capacity, 0));
  const notEnoughStorage = computed(() => hasApiStorageError.value || storageDeficit.value > 0);
  const canCopy = computed(() => albumMetrics.value && !albumHasTooManyFiles && !notEnoughStorage.value);

  const progressBarConfig = computed(() => {
    const storageDeficitPercent = Math.ceil((storageDeficit.value / currentUser.storage_capacity) * 100);
    const items = [
      {
        value: currentUser.storagePercentUsed,
        label: 'Storage Used',
        color: 'heroblue',
        icon: 'i-ri-square-fill'
      }
    ];

    if (notEnoughStorage.value) {
      items.push(
        {
          value: (albumSizePercentOfStorage.value - storageDeficitPercent) + 1,
          label: 'Storage Available',
          color: 'green',
          icon: 'i-ri-square-fill'
        },
        {
          value: storageDeficitPercent,
          label: 'Storage Needed',
          color: 'red',
          icon: 'i-ri-square-fill'
        }
      );
    } else {
      items.push({
        value: albumSizePercentOfStorage.value - storageDeficitPercent,
        label: 'Storage Needed',
        color: 'green',
        icon: 'i-ri-square-fill'
      });
    }

    return {
      max: 100 + storageDeficitPercent,
      items
    };
  });

  async function copyFiles(copyTags) {
    try {
      isCopyingAlbum.value = true;
      const albumCopy = await useAlbumApi().copyAlbum({
        album: props.album,
        copyTags
      });

      useSessionStore().getCurrentUser();

      useSuccessToast().add({
        id: 'album-copy',
        description: `The album <strong>${props.album.name}</strong> was successfully copied to your account.`,
        timeout: 8_000,
        actions: [
          {
            label: 'View New Album',
            click: () => {
              navigateTo(useBuildRoute().toAlbum({album: albumCopy}))
            }
          }
        ]
      });

      props.modal.close();

    } catch (e) {
      if (e.statusCode === 409) {
        await useSessionStore().getCurrentUser();
        hasApiStorageError.value = true;
      } else {
        hasGenericError.value = true;
      }
      throw e;
    } finally {
      isCopyingAlbum.value = false;
    }
  }

  function buyStorage() {
    window.location = EXTERNAL_URLS.storage;
  }

  onMounted(async () => {
    if (albumHasTooManyFiles) {
      return;
    }

    try {
      isLoadingMetrics.value = true;
      const [metrics] = await Promise.all([
        useAlbumApi().getAlbumMetrics({
          id: props.album.id,
          userId: props.album.user.id,
          shareToken: props.album.share_token
        }),
        useSessionStore().getCurrentUser()
      ]);
      albumMetrics.value = metrics;
    } catch (e) {
      hasGenericError.value = true;
    } finally {
      isLoadingMetrics.value = false;
    }
  });

</script>
