<template>
  <div class="border-b border-gray-200">
    <u-input
      v-if="isEditing"
      @keypress.enter.stop.prevent="add"
      v-model="name"
      :placeholder="placeholder"
      variant="none"
      size="sm"
      autofocus
      :ui="inputConfig"
    >
      <template #trailing>
        <u-button
          size="xs"
          icon="i-ri-check-line"
          @click="add"
          :disabled="!name"
          :loading="isSaving"
          label="Create"
        />
      </template>
    </u-input>
    <u-button
      v-else
      variant="link"
      :icon="COMMON_ICONS.add"
      block
      @click="startEditing"
      :ui="{block: 'justify-start'}"
    >
      {{label}}
    </u-button>

  </div>
</template>

<script setup>
  const resetEvent = useEventBus('core-add-new-to-list-reset')
  const emit = defineEmits(['added', 'error', 'partial-entry']);
  const props = defineProps({
    label: {
      type: String,
      default: 'Add'
    },
    placeholder: String,
    addFn: {
      type: Function,
      required: true
    }
  });

  const isEditing = ref(false);
  const name = ref(null);
  const isSaving = ref(false);

  const inputConfig = {
    base: 'border border-solid',
    trailing: {
      padding: {sm: 'pr-24'}
    },
    icon: {
      trailing: {
        padding: {sm: 'px-1'},
        pointer: ''
      }
    }
  };

  function startEditing() {
    isEditing.value = true;
  }
  async function add() {
    if (!name.value) {
      return;
    }

    isSaving.value = true;

    try {
      await props.addFn(name.value);
      reset();
      emit('added');
    } catch (e) {
      emit('error', e);
    } finally {
      isSaving.value = false;
    }
  }
  function reset() {
    name.value = null;
    isEditing.value = false;
  }

  onMounted(() => {
    resetEvent.on(reset);
  });

  onUnmounted(() => {
    resetEvent.off(reset);
  });

</script>
