<template>
  <div class="">
    <core-modal-header>Print</core-modal-header>

    <div class="p-3 sm:p-5">
      <core-contextual-loading-box v-if="pending" size="sm" key="loading" loading-text="Loading Products" />
      <core-contextual-error-box v-else-if="error" size="sm" key="error" :action="refresh" />

      <ul v-else class="list-unstyled grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-5">
        <li v-for="product in printProducts" :key="product.name" @click="select(product)" class="border-2 border-charcoal-200 bg-charcoal-50 rounded flex flex-col items-center justify-center text-center p-2 sm:p-6 cursor-pointer relative transition-all hover:bg-sage-50 hover:border-sage-300">
          <img v-if="product.imageUrl" class="w-[75px] mb-2" :src="product.imageUrl" :alt="product.name">
          <h4 class="text-lg leading-none mb-2">{{product.name}}</h4>
          <p class="text-gray-400 text-base mb-2.5 leading-none" v-if="product.type || product.description">{{product.type || product.description}}</p>
          <p v-if="product.startingPrice" class="text-base  leading-none">From <pricing-product-price-display :base-price="product.startingPrice" :product-id="product.productId" /></p>
        </li>
      </ul>

    </div>

  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';

  const emit = defineEmits(['single-photo-print'])
  const props = defineProps({
    file: Object,
    context: Object,
    contextType: String,
    modal: Object
  });

  const projectsStore = useProjectsStore();
  const {containerAutoPrintProjectTypes, singleFileAutoPrintProjectTypes} = storeToRefs(projectsStore);

  const {pending, error, refresh} = useLazyAsyncData(
    'auto-print-project-types',
    () => projectsStore.getAutoPrintProjectTypes(),
    {
      server: false
    }
  );


  const printProducts = computed(
    () => props.context
      ? containerAutoPrintProjectTypes.value
      : singleFileAutoPrintProjectTypes.value
  );

  function select(product) {
    if (product === photoPrintConf) {

      if (props.context) {
        const query = buildQueryString({
          addContainer: true,
          contextId: props.context?.id,
          contextType: props.contextType
        });

        navigateTo(`/app/photo-prints${query}`, {external: true});
      } else {
        emit('single-photo-print')
      }

    } else {
      const query = buildQueryString({
        getCreateConf: true,
        fileId: props.file?.id,
        contextId: props.context?.id,
        contextType: props.contextType,
        subtype: product.subtype,
        sku: product.sku
      });

      navigateTo(`/app/print-designer${query}`, {external: true});
    }

  }

</script>
