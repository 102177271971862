<template>
  <div class="flex flex-col sm:flex-row">

    <ul class="list-none flex max-w-full overflow-x-auto whitespace-nowrap sm:flex-col sm:w-38 sm:p-4 gap-x-4 py-2 px-4 bg-shell-400 sm:bg-white">
      <li v-for="state in states">
        <button class="w-full py-1.5 text-left text-nowrap text-charcoal font-semibold" @click="goToState({state})" :class="{underline: state.key === currentStateKey}">{{state.label}}</button>
      </li>
    </ul>

    <u-divider orientation="vertical" class="hidden sm:flex" />
    <u-divider class="sm:hidden" />

    <div class="flex-1 flex flex-col p-2 sm:p-4">
      <component class="flex-1 small" :is="currentState.component" v-model="internalDateRange" :year-range="yearRange" />

      <div class="flex justify-end mt-4 gap-2">
        <u-button variant="ghost" color="charcoal" @click="close">Cancel</u-button>
        <u-button @click="apply">Apply</u-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import cloneDeep from 'lodash.clonedeep';
  import {
    CoreDateRangePickerStateCustomRange,
    CoreDateRangePickerStateSpecificDate,
    CoreDateRangePickerStateMonthYear,
    CoreDateRangePickerStateYear,
    CoreDateRangePickerStateDecade,
  } from '#components';

  const emit = defineEmits(['close']);

  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1500, useDayjs().utc().year()]
    }
  });

  const dayJs = useDayjs();

  const dateRange = defineModel();
  const internalDateRange = ref();

  watch(dateRange, newVal => {
    internalDateRange.value = cloneDeep(newVal);
  },
  {
    immediate: true,
    deep: true
  });

  const states = [
    {
      label: 'Date Range',
      key: 'custom-range',
      component: CoreDateRangePickerStateCustomRange,
      getDisplayValue() {
        return `${dayJs(internalDateRange.value?.val[0]).format('ll')} - ${dayJs(internalDateRange.value?.val[1]).format('ll')}`;
      }
    },

    {
      label: 'Month/Year',
      key: 'month-year',
      component: CoreDateRangePickerStateMonthYear,
      getDisplayValue() {
        return dayJs(internalDateRange.value?.val[0]).format('MMMM YYYY');
      }
    },
    {
      label: 'Day',
      key: 'specific-day',
      component: CoreDateRangePickerStateSpecificDate,
      getDisplayValue() {
        return dayJs(internalDateRange.value?.val[0]).format('LL');
      }
    },
    {
      label: 'Year',
      key: 'year',
      component: CoreDateRangePickerStateYear,
      getDisplayValue() {
        return dayJs(internalDateRange.value?.val[0]).format('YYYY');
      }
    },
    {
      label: 'Decade',
      key: 'decade',
      component: CoreDateRangePickerStateDecade,
      getDisplayValue() {
        return `${dayJs(internalDateRange.value?.val[0]).format('YYYY')}s`;
      }
    }
  ];

  const currentStateKey = ref(internalDateRange.value?.type || states[0].key);
  const currentState = computed(() => states.find(s => s.key === currentStateKey.value));

  watch(currentState, () => {
    internalDateRange.value = cloneDeep(dateRange.value);
  });

  function goToState({state}) {
    currentStateKey.value = state.key;
  }

  function close() {
    emit('close');
  }

  function apply() {
    if (!internalDateRange.value?.val) {
      return;
    }

    dateRange.value = {
      ...internalDateRange.value,
      type: currentStateKey,
      displayText: currentState.value.getDisplayValue()
    };
    close();
  }

</script>
