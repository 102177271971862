import QRCode from 'easyqrcodejs';

export function generateQRCode(elem, {text, size, showTitle = true} = {}) {
  const base = {
    size: 1200,
    text: 120,
    quietZone: 40,
    titleTop: 80,
    titleHeight: 120
  };

  const scale = size ? size / base.size : 1;
  const scaleVal = val => Math.floor(val * scale);

  const fontSize = scaleVal(base.text);
  const quietZone = scaleVal(base.quietZone);
  const titleTop = scaleVal(base.titleTop);
  const titleHeight = scaleVal(base.titleHeight);
  const darkColor = '#444444';
  const primaryColor = '#2b91cf';

  return new QRCode(elem, {
    text,
    width: size || base.size,
    height: size || base.size,
    quietZone,
    correctLevel: QRCode.CorrectLevel.M,
    colorDark: darkColor,
    title: showTitle ? 'FOREVER®' : '',
    titleFont: `bold ${fontSize}px ProximaNova`,
    titleColor: darkColor,
    titleTop,
    titleHeight,
    PO: darkColor,
    PI: primaryColor,
    AO: darkColor,
    AI: primaryColor,
    timing: darkColor
  });
}
