import {defineStore} from 'pinia';

export const makePeopleStore = (id = 'people') => defineStore(id, {
  state: () => ({
    people: null,
    hasMore: false,
    cursor: undefined,
    collectionDescriptor: null,
    person: null
  }),

  actions: {
    async getPeople({nextPage, search, order, namedStatus, displayStatus = [PEOPLE_DISPLAY_STATUS.visible], userId} = {}) {

      if (!nextPage) {
        this.clearPeople();
        this.collectionDescriptor = {userId, search, order, namedStatus, displayStatus};
      }

      const data = await usePeopleApi().getPeople({
        cursor: this.cursor,
        ...this.collectionDescriptor
      });

      this.people = [...(this.people || []), ...data.people];
      this.hasMore = data.people.length === data.per_page;
      this.cursor = data.next_cursor;

      return this.people;
    },

    async updatePerson({id, updates}) {
      const updatedPerson = await usePeopleApi().updatePerson({id, updates});

      if (this.person && id === this.person.id) {
        Object.assign(this.person, updatedPerson);
      }

      const listPerson = this.people?.find(a => a.id === id);

      //if we have a list and this person is in a list that does not include hidden people, remove from list
      if (listPerson && !this.collectionDescriptor.displayStatus.includes(PEOPLE_DISPLAY_STATUS.hidden) && updatedPerson.display_status === PEOPLE_DISPLAY_STATUS.hidden) {
        this.people = this.people.filter(person => person.id !== id);
      } else if (listPerson) {
        Object.assign(listPerson, updatedPerson);
      }

      useFilesStore().refreshFileDetails({updatedPerson: updatedPerson});

      return updatedPerson;
    },

    async getPerson({id, userId, shareToken}) {
      const person = await usePeopleApi().getPerson({id, userId, shareToken});

      person.isOwnedByUser = useUserContext({entity: person}).isEntityOwner.value;

      if (this.person?.id === id) {
        Object.assign(this.person, person);
      } else {
        this.person = person;
      }

      return this.person;
    },

    async getPeopleOne({id, userId, shareToken}) {
      const person = await usePeopleApi().getPerson({id, userId, shareToken});
      const updateIndex = this.people?.findIndex(a => a.id === id);

      if (updateIndex > -1) {
        this.people.splice(updateIndex, 1, person);
      }

      return person;
    },

    clearPeople() {
      this.people = null;
      this.hasMore = false;
      this.cursor = undefined;
      this.collectionDescriptor = null;
    },

    async mergePeople({source, destination}) {
      await usePeopleApi().mergePeople({
        source,
        destination
      });

      if (this.people?.length) {
        this.people = this.people.filter(person => person.id !== source.id);
      }

      const filesStore = useFilesStore();
      filesStore.updateContext({contextId: destination.id, contextType: FILE_CONTEXTS.person});
      filesStore.refreshFileDetails({updatedPerson: source});
    }
  }
});

export const usePeopleStore = makePeopleStore();
