export const SLIDESHOW_PRELOAD_FILES_AT_INDEX_OFFSET = 10;
export const SLIDESHOW_STAGED_SLIDES_AROUND_CURRENT = 3;
export const SLIDESHOW_FILE_PAN_THRESHOLD_PERCENT = 15;
export const SLIDESHOW_SUPPORTED_FILE_TYPES = [
  'photo',
  'video',
  'project',
  'document'
];

export const SLIDESHOW_TRANSITION_STYLES = [
  {
    label: 'Pan',
    value: 'pan',
    cssClass: 'pan'
  },
  {
    label: 'Dissolve',
    value: 'dissolve',
    cssClass: 'dissolve'
  },
  {
    label: 'Dissolve Up',
    value: 'dissolve-up',
    cssClass: 'dissolve-up'
  },
  {
    label: 'Random',
    value: 'random',
    cssClass: 'random'
  },
  {
    label: 'None',
    value: null,
    cssClass: ''
  }
];
export const SLIDESHOW_DEFAULT_TRANSITION_STYLE = 'dissolve-up';
export const SLIDESHOW_TIME_PER_SLIDE_OPTIONS = [
  {
    label: 'Fast (3s)',
    value: 3
  },
  {
    label: 'Medium (6s)',
    value: 6
  },
  {
    label: 'Slow (9s)',
    value: 9
  }
];
export const SLIDESHOW_DEFAULT_TIME_PER_SLIDE = 6;
export const SLIDESHOW_HIDE_CONTROL_BAR_AFTER = 3;
export const SLIDESHOW_STATE_PERSIST_KEY = 'mars-slideshow-settings-state';
