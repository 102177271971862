
export const SHARE_ROUTES = {
  tag: '/app/users/:userId/tags/:id/:shareToken',
  album: '/app/users/:userId/albums/:id/:shareToken',
  container: '/app/users/:userId/containers/:id/:shareToken',
  file: '/app/users/:userId/files/:fileId/:shareToken',
  profile: '/app/users/:userId',
  embed: '/app/external/embed/:userId/:fileId/:shareToken',
  gift: '/app/gift/:token'
};

export const SHARE_EMBED_FRAME_MAX_VIDEO_DIMENSIONS = 560;
