const boundScroller = ref(null);
const activeThreshold = ref(0);
const showButton = computed(() => Boolean(boundScroller.value));

export function useBackToTop() {
  return {
    bindToScroller({elem, threshold = 500}) {
      boundScroller.value = elem;
      activeThreshold.value = threshold;
    },
    unbind() {
      boundScroller.value = null;
    },

    boundScroller,
    showButton,
    activeThreshold
  }
}
