<template>
  <core-select-menu-selections v-if="showSelectionTokens" v-model:selected="selected" context-type="album" />

  <u-select-menu
    class="album-select-menu"
    v-model="selected"
    :size="size"
    :leading-icon="COMMON_ICONS.album"
    by="id"
    option-attribute="name"
    multiple
    :searchable="getAlbums"
    :searchable-lazy="false"
    :creatable="!disableCreate"
    show-create-option-when="always"
    ref="selectElement"
    :searchable-placeholder="searchablePlaceholder"
    :popper="popper"
    :ui-menu="{base: 'scrollbar-light'}"
    :ui="ui"
    @close="close"
  >
    <template #option="{option}">
      <album-avatar :album="option" size="md" />
      <span class="break-long-string">{{option.name}}</span>
    </template>

    <template #empty>No albums.</template>

    <template #option-create="{ option }">
      <span class="flex-shrink-0">New Album:</span>
      <album-avatar :album="option" size="md" />
      <span class="block break-long-string">{{ option.name }}</span>
    </template>

    <template #label>
      <template v-if="selected.length">{{selected.length}} Selected</template>
      <template v-else>{{placeholderText}}</template>
    </template>

    <slot />

  </u-select-menu>
</template>

<script setup>
  const emit = defineEmits(['update:modelValue', 'close']);
  const props = defineProps({
    modelValue: Array,
    autofocus: Boolean,
    disableCreate: Boolean,
    size: {
      type: String,
      default: 'lg'
    },
    popper: Object,
    showSelectionTokens: {
      type: Boolean,
      default: true
    },
    ui: Object
  });

  const placeholderText = 'Select Albums';
  const selected = ref([]);
  const selectElement = ref();

  //note: this construct is necessary because for some reason selecting a album triggers a new request to the api
  const currentOptionsDescriptor = {
    search: '',
    options: null
  };

  const searchablePlaceholder = computed(() => !props.disableCreate ? 'Search or create albums (start typing)' : 'Search albums (start typing)');

  async function getAlbums(search) {
    if (search !== currentOptionsDescriptor.search || !currentOptionsDescriptor.options) {
      currentOptionsDescriptor.search = search;
      const data = await useAlbumApi().getAlbums({search});
      currentOptionsDescriptor.options = data.albums.filter(a => a.name.toLowerCase().includes(search.toLowerCase()));
    }

    return currentOptionsDescriptor.options;
  }

  function close() {
    emit('close');
  }

  watch(selected, (albums) => {
    albums.forEach(async (album, index) => {
      if (album.id) {
        return;
      }

      const newAlbum = await useAlbumApi().createAlbum(album);

      selectElement.value.query = '';
      selected.value.splice(index, 1, newAlbum);
    });

    emit('update:modelValue', albums);

  }, {deep: true});

  watch(() => props.modelValue, newValue => {
    selected.value = newValue;
  },
  {
    immediate: true
  });

  onMounted(() => {
    if (props.autofocus) {
      // - using the selectMenu ref threw an error
      // - using the native autofocus attr on the u-select worked 1 time per page refresh
      useFocus(document.querySelector('.album-select-menu button'), {
        initialValue: true
      });
    }
  });
</script>
