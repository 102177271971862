<template>
  <div>
    <ul class="list-unstyled" v-auto-animate>

      <li
        v-for="(trackedOption, index) in trackedOptions"
        :key="trackedOption.originalOption.id || index"
        @click="toggleSelect(trackedOption)"
        class="flex px-3 py-2 cursor-pointer hover:bg-charcoal-100 group transition-all bg-white odd:bg-gray-100 items-center gap-2"
      >
        <div class="flex-1 select-none break-long-string">
          <slot name="option-item" :option="trackedOption.originalOption" />
        </div>

        <u-checkbox :model-value="Boolean(trackedOption.selected)" />
      </li>
    </ul>

    <div v-if="isLoadingMoreOptions" class="text-center">
      <core-busy-dots :width="40" />
    </div>
  </div>
</template>

<script setup>
  import isEqual from 'lodash.isequal';

  const props = defineProps({
    options: Array,
    hasMoreOptions: Boolean,
    isLoadingMoreOptions: Boolean,
    scrollElement: Object
  });

  const model = defineModel();
  const emit = defineEmits(['load-more-options'])

  onMounted(async () => {

    //need this for scroll elem to be defined
    await nextTick();

    useInfiniteScroll(
      props.scrollElement,
      () => emit('load-more-options'),
      {
        distance: 100,
        interval: 1000,
        canLoadMore: () => props.hasMoreOptions && !props.isLoadingMoreOptions
      }
    );

  });

  const trackedOptions = computed(() => props.options.map(option => ({
    get selected() {
      return model.value?.find(aOption => isEqual(aOption, option));
    },
    originalOption: option
  })));

  function toggleSelect(trackedOption) {
    const newValue = model.value.slice(0);

    if (trackedOption.selected) {
      const optionIndex = model.value.findIndex(option => isEqual(option, trackedOption.originalOption));
      newValue.splice(optionIndex, 1);
    } else {
      newValue.push(trackedOption.originalOption);
    }

    emit('update:modelValue', newValue);
  }

</script>
