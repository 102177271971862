<template>
  <ul class="list-none text-base overflow-y-auto max-h-56 grid grid-cols-3 gap-1.5 sm:gap-2.5 scrollbar-light pr-2 sm:-mr-2" ref="yearPickerEl">
    <li
      v-for="year in years"
      class="cursor-pointer text-center px-4 sm:px-8 py-3 text-sm transition-colors rounded border border-gray-200 shadow-sm leading-none"
      :class="[
        {'bg-heroblue text-white font-semibold': year === selection},
        {'hover:bg-gray/10': year !== selection}
      ]"
      @click="setYear(year)"
      :data-year-value="year"
    >
      {{year}}
    </li>
  </ul>
</template>

<script setup>
  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1500, useDayjs().utc().year()]
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const years = Array.from(Array((props.yearRange[1] - props.yearRange[0]) + 1), (n, i) => props.yearRange[1] - i);
  const selection = computed({
    get() {
      const theDate = (dateRange.value?.val && dateRange.value?.val[0]) || dayJs();

      return theDate.utc().year();
    },
    set(value) {
      dateRange.value.val = [
        dayJs().utc().year(value).startOf('year'),
        dayJs().utc().year(value).endOf('year')
      ];
    }
  });

  function setYear(year) {
    selection.value = year;
  }

  const yearPickerEl = ref();

  onMounted(async () => {
    selection.value = selection.value; //note: needed to update the dateRange model to the appropriate range

    const {y} = useScroll(yearPickerEl.value, { behavior: 'smooth' });
    const itemEl = yearPickerEl.value.querySelector(`[data-year-value="${selection.value}"]`);

    y.value += itemEl.offsetTop - 100;
  });
</script>
