<template>
  <u-avatar
    icon="i-heroicons-user-circle-16-solid"
    :src="person.avatarImage"
    :ui="uiConfig"
  />
</template>

<script setup>
  const props = defineProps({
    person: Object,
    ui: Object
  });

  const uiConfigBase = {
    background: 'bg-shell dark:bg-shell-700',
    icon: {base: 'text-shell-100 dark:text-shell-200 !h-full !w-full'}
  };

  const uiConfig = Object.assign(uiConfigBase, props.ui);
</script>
