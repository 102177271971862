<template>
  <section>
    <core-modal-header>Share {{album.name}}</core-modal-header>

    <core-modal-body>
      <div class="grid sm:grid-cols-2 divide-y sm:divide-x sm:divide-y-0 divide-gray-200">
        <div class="sm:pr-6 pb-4 sm:pb-0">
          <core-share :share-object="album" share-type="album" />
        </div>
        <div class="sm:pl-6 pt-4 sm:pt-0">
          <album-permission-editor v-model:album="editedAlbum" />
        </div>
      </div>
    </core-modal-body>

    <core-modal-footer v-if="isRoot">
      <u-button variant="ghost" color="charcoal" @click="modal.close()">Cancel</u-button>
      <u-button :loading="isSaving || isLoading" @click="saveAlbum">Save</u-button>
    </core-modal-footer>
  </section>
</template>

<script setup>
  const emit = defineEmits(['success']);

  const props = defineProps({
    album: Object,
    modal: Object
  });

  const editedAlbum = ref(Object.assign({}, props.album));
  const isSaving = ref(false);
  const isLoading = ref(false);
  const isRoot = !props.album.parent_album;

  if (!editedAlbum.value.share_token) {
    editedAlbum.value = await useAlbumsStore().getAlbum({id: editedAlbum.value.id});
  }

  async function saveAlbum() {
    isSaving.value = true;

    try {
      await useAlbumsStore().updateAlbum({
        id: editedAlbum.value.id,
        updates: {
          privacy: editedAlbum.value.privacy,
          permission_group_ids: editedAlbum.value.permission_group_ids
        }
      });

      emit('success');
      //props.modal.close();
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
