const apiPaths = {
  container: '/users/:userId/containers/:id',
  containerCreate: '/users/:userId/containers'
};

export function useContainerApi() {

  return {
    async createContainer({fileIds}) {
      const data = await useMarsApiV3().post(apiPaths.containerCreate, {
        params: {
          route: {
            userId: useSessionStore().currentUserId
          }
        },
        body: {
          forever_file_ids: fileIds
        }
      });

      return data.container;
    },

    async getContainer({id, userId = useSessionStore().currentUserId, shareToken}) {
      const params = {
        route: {
          userId,
          id
        },
        query: {
          share_token: shareToken
        }
      };

      const data = await useMarsApiV3().fetch(apiPaths.container, {params});

      return containerMapper(data.container);
    }
  };
}
