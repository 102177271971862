import {storeToRefs} from 'pinia';
import {makeFilesStore} from "~/stores/files.js";

const state = {
  selected: ref(new Map())
};

export function useFileGroupSelection({filesStoreId} = {}) {

  const fileSelection = useSelection();

  const getGroupFiles = async ({groupName}) => {
    const filesStore = filesStoreId
      ? makeFilesStore(filesStoreId)()
      : useFilesStore();

    const {files, hasMore, isBuffering} = storeToRefs(filesStore);
    const groupFiles = computed(() => files.value.filter(file => file.group.name === groupName));

    while(hasMore.value && (files.value.findLastIndex(file => file.group.name === groupName) === files.value.length - 1) && groupFiles.value.length < FILE_MAX_SELECTION) {

      await filesStore.getFiles({nextPage: true});
      await new Promise(resolve => watch(() => isBuffering.value, newVal => {
        if (!newVal) {
          resolve();
        }
      }));
    }

    return groupFiles.value;
  };

  const updateGroupSelectionFromFiles = ({select, unselect}) => {
    const affectedGroups = [...(select || []), ...(unselect || [])].reduce((affectedSet, file) => {
      affectedSet.add(file.group.name);

      return affectedSet;
    }, new Set());

    affectedGroups.forEach(async (groupName) => {
      const files = await getGroupFiles({groupName});
      const isGroupSelected = files.every(file => fileSelection.has({id: file.id}));
      const selectionState = state.selected.value.get(groupName) || false;

      if (selectionState !== isGroupSelected) {
        state.selected.value.set(groupName  , isGroupSelected);
      }
    });
  };

  return {
    async toggle({group}) {

      const files = await getGroupFiles({groupName: group.name});

      const payload = this.has(group)
        ? {unselect: files}
        : {select: files};

      fileSelection.update(payload);
    },
    has(group) {
      return state.selected.value.get(group.name) || false;
    },
    updateGroupSelectionFromFiles,
    clearGroupSelection() {
      state.selected.value.clear();
    }
  };
}
