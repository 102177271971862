const apiPaths = {
  shorten: '/url-shortener'
};

export function useUrlShortener() {
  const shortenerBaseUrl = useRuntimeConfig().public.apiUrlShortenerHost;

  return {
    async getShortenedUrl(url) {
      const data = await $fetch(apiPaths.shorten, {
        baseURL: shortenerBaseUrl,
        method: 'POST',
        body: {url}
      });

      return data.shortUrl;
    }
  };
}
