import {defineStore} from 'pinia';
import {autoPrintConfs, photoPrintConf} from '~/constants/projects.js';
import {getBatchPhotosetOptions} from '~/utils/pricing.js';

export const useProjectsStore = defineStore('projectsStore', {
  state: () => ({
    projectCategories: [],
    projectTypes: [],
    autoPrintProjectTypes: null,
    photoPrintsOrderStatus: null,
    photoPrintsOptions: null
  }),

  actions: {
    async getProjectCategories() {
      this.projectCategories = await useProjectsApi().fetchProjectCategories();
    },

    async getProjectTypes() {
      if (!this.projectTypes.length) {
        const projectTypesRes = await useProjectsApi().fetchProjectTypes();

        this.projectTypes = projectTypesRes?.group.children.filter(item => item.visible)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;
          });
      }
    },

    async getAutoPrintProjectTypes() {
      if (this.autoPrintProjectTypes) {
        return this.autoPrintProjectTypes;
      }

      const autoPrintProjectTypes = await useProjectsApi().fetchAutoPrintProjectTypes();

      this.autoPrintProjectTypes =  Object.keys(autoPrintProjectTypes).reduce((confs, productKey) => {
        const confSet = autoPrintConfs[productKey];

        if (confSet) {
          confSet.forEach(conf => {
            const productTypeConf = autoPrintProjectTypes[productKey].find(pt => pt.sku === conf.sku);

            if (productTypeConf && productTypeConf.sizes.length) {
              confs.push({
                ...conf,
                subtype: productTypeConf.subtype,
                productId: productTypeConf.id,
                startingPrice: productTypeConf.sizes.sort((a, b) => a.price_cents - b.price_cents)[0].price_cents
              });
            }

          });
        }

        return confs;
      }, []);
    },

    async getPhotoPrintsOrderStatus() {
      const status = await useProjectsApi().getPhotoPrintsOrderStatus();
      this.photoPrintsOrderStatus = status;
      return status;
    },

    async getPhotoPrintsOptions() {
      if (!this.photoPrintsOptions) {
        const photoPrintsBatch = await useProjectsApi().getPhotoPrintBatch();
        const photoPrintProduct = await useProjectsApi().getPhotoPrintProduct({productId: photoPrintsBatch.product_id});

        this.photoPrintsOptions = getBatchPhotosetOptions(photoPrintsBatch, photoPrintProduct)

      }

      return this.photoPrintsOptions;
    }
  },

  getters: {
    containerAutoPrintProjectTypes: state => state.autoPrintProjectTypes && [...state.autoPrintProjectTypes.filter(pt => pt.isContainerPrint), photoPrintConf],
    singleFileAutoPrintProjectTypes: state => state.autoPrintProjectTypes?.filter(pt => !pt.isContainerPrint) //state.autoPrintProjectTypes && [...state.autoPrintProjectTypes.filter(pt => !pt.isContainerPrint), photoPrintConf]
  }
});
