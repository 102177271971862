const apiPaths = {
  projects: '/users/:userId/projects',
  projectCopy: '/users/:userId/projects/:id/copy',
  categories: '/project_categories',
  projectTypes: '/groups/print-project-type',
  autoPrint: '/products/fast_print',
  printBatchStatus: '/users/:userId/photo_print_batches/status',
  photoPrintBatch: '/photo_print_batches',
  photoPrintsProduct: '/products/:productId'
};

const projectCateogryNameMap = {
  'photo-gifts': 'Photo Gifts',
  'wall-art': 'Wall Art',
  'photo-books': 'Photo Books',
  'calendars': 'Calendars',
  'cards': 'Cards',
  'drinkware': 'Drinkware'
};

export function useProjectsApi() {
  return {
    async getProjects({cursor, search, order, filter} = {}) {
      const query = {
        cursor,
        search,
        order,
        slug: filter && filter.param
      };

      const data = await useMarsApiV3().fetch(apiPaths.projects, {
        params: {
          route: {
            userId: useSessionStore().currentUserId
          },
          query
        }
      });
      return Object.assign(
        data,
        {
          files: data.projects.map(p => mapFile(p, {isProject: true})).map(mapProject)
        }
      );
    },

    async fetchProjectCategories() {
      const res = await useMarsApiV3().fetch(apiPaths.categories);

      const categories = res.project_categories.map(item => {
        const label = projectCateogryNameMap[item.slug];
        if (label) {
          return {
            label,
            param: item.slug
          }
        }
      }).filter(Boolean).sort((a, b) => a.label.localeCompare(b.label));

      categories.unshift({
        label: 'All Projects',
        param: null
      });

      return categories;
    },

    async copyProject({id, name}) {
      const data = await useMarsApiV3().post(
        apiPaths.projectCopy,
        {
          params: {
            route: {userId: useSessionStore().currentUserId, id}
          },
          body: {name}
        }
      );

      return mapProject(mapFile(data.project, {isProject: true}));
    },

    fetchProjectTypes() {
      return useStoreApi().fetch(apiPaths.projectTypes);
    },

    async fetchAutoPrintProjectTypes() {
      const res = await useStoreApi().fetch(apiPaths.autoPrint);

      return res.products;
    },

    async getPhotoPrintsOrderStatus() {
      const res = await useJupiterApi().fetch(apiPaths.printBatchStatus, {
        params: {
          route: {
            userId: useSessionStore().currentUserId
          }
        }
      });

      return res.status;
    },

    async getPhotoPrintBatch() {
      const res = await useJupiterApi().post(apiPaths.photoPrintBatch);
      return res.photo_print_batch;
    },

    async getPhotoPrintProduct({productId}) {
      const params = {route: {productId}};
      const res = await useStoreApi().fetch(apiPaths.photoPrintsProduct, {params});
      return res.product;
    }
  };
}
