<template>
  <div class="cursor-pointer w-7 h-7 transition-all duration-300" @click.stop="toggle" tabindex="0">
    <div class="w-5 h-5 absolute ml-1 mt-1 rounded-full"  :class="{'bg-white': selected}" />

    <u-icon
      name="i-ri-checkbox-circle-fill"
      class="w-7 h-7 transition-all absolute duration-300"
      aria-hidden="false"
      :class="iconClass"
    />
  </div>
</template>

<script setup>
  const {hasAny} = useSelection();
  const selected = defineModel('selected');
  const props = defineProps({
    dark: Boolean
  })

  const iconClass = computed(() => {
    const color = props.dark ? 'text-gray-400' : 'text-white';
    const shared = 'group-hover:opacity-75 hover:!opacity-100';
    if (selected.value) {
      return 'text-heroblue block';
    } else {
      return hasAny.value ? `opacity-50 ${shared} ${color}` : `opacity-50 md:opacity-0 ${shared} ${color}`;
    }
  });

  function toggle() {
    selected.value = !selected.value;
  }
</script>
