<template>
  <div class="f-file-exif_download_modal">

    <core-modal-header>
      <template v-if="hasWritables">Download With Updated Metadata?</template>
      <template v-else>Unable to Update Metadata</template>
    </core-modal-header>

    <core-modal-body>
      <template v-if="hasWritables">
        <p class="mb-4">Download photos and documents with updated metadata?</p>
        <u-alert
          color="heroblue"
          :icon="COMMON_ICONS.information"
          title="This process takes slightly longer."
          description="You'll get a notification once your download is ready."
        />
      </template>

      <p v-else>Cannot update file metadata, but you can download as is.</p>
    </core-modal-body>

    <core-modal-footer>
      <template v-if="hasWritables">
        <u-button @click="no" color="charcoal" variant="soft">No Thanks</u-button>
        <u-button @click="yes">Yes, Update Metadata</u-button>
      </template>

      <template v-else>
        <u-button @click="no">Download</u-button>
      </template>

      <template #help> <!-- todo:figure out -->
        <u-button icon="help" color="charcoal" :href="EXTERNAL_URLS.helpCenterMetadataFaq" target="_blank" rel="noopener">What is Metadata?</u-button>
      </template>

    </core-modal-footer>
  </div>
</template>

<script setup>
  const emit = defineEmits(['yes', 'no']);

  const props = defineProps({
    hasWritables: {
      type: Boolean,
      default: true
    },
    hasMultiple: {
      type: Boolean
    }
  });

  function yes() {
    emit('yes');
  }
  function no() {
    emit('no');
  }
</script>
