
export function arrayReduceByAverage(arr, toLength) {
  if (arr.length <= toLength) {
    return arr;
  }

  const averagingQuotient = arr.length / toLength;
  let tracker = averagingQuotient;

  return arr.filter((item, ind) => {
    if (!ind) {
      return true;
    }

    if (tracker > 1) {
      tracker -= 1;
      return false;
    }

    tracker += averagingQuotient - 1;

    return true;
  });
}
