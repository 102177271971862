<template>
  <u-avatar
    :size="size"
    :alt="group.name"
    :src="group.avatarImage"
    :ui="{background: group.avatarBg, rounded}"
  />
</template>

<script setup>
  const props = defineProps({
    group: Object,
    size: {
      type: String,
      default: 'md'
    }
  });

  const rounded = computed(() => {
    switch (props.size) {
      case '2xs':
      case 'xs':
      case 'sm':
      case 'md':
        return 'rounded';
      default:
        return 'rounded-lg';
    }
  });
</script>
