import { useStorage } from '@vueuse/core';

const state = {
  gridSize: useStorage('FILE_LIST_GRID_SIZE', FILE_GRID_SIZES.md)
}

export function useFileListGrid() {
  return {
    size: state.gridSize,
    setSize(size) {
      state.gridSize.value = size;
    }
  }
}
