<template>
  <section>
    <core-modal-header>New Print Project</core-modal-header>

    <core-modal-body>
      <transition-group name="f-slide-fade">
        <core-contextual-loading-box v-if="pending" size="sm" key="loading" loading-text="Loading Products" />
        <core-contextual-error-box v-else-if="error" size="sm" key="error" :action="refresh" />

        <div v-else class="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4">
          <div
            v-for="type in projectTypes"
            :key="type.id"
            class="col-span-1">

            <u-button
              class="items-center no-underline flex-col"
              color="charcoal"
              variant="ghost"
              block
              :title="`Browse ${type.name}`"
              :to="type.url"
              :external="true"
            >
              <img class="w-[100px] h-[100px]" :src="type.image_url" :alt="type.name" />
              <h4 class="font-semibold text-lg">{{type.name}}</h4>
              <p class="text-md">
                <span class="text-charcoal-400">From </span>
                <pricing-product-price-display :base-price="type.starting_price_cents" :promotion="type.primary_promotion" />
              </p>
            </u-button>
          </div>
        </div>
      </transition-group>
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
    </core-modal-footer>
  </section>
</template>

<script setup>
  import {storeToRefs} from 'pinia';

  const projectsStore = useProjectsStore();
  const {projectTypes} = storeToRefs(projectsStore);

  const props = defineProps({
    modal: Object
  });

  const {refresh, pending, error} = await useLazyAsyncData(
    'projectTypes',
    () => projectsStore.getProjectTypes()
  );

  function close() {
    props.modal.close();
  }
</script>
