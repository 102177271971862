<template>
  <div v-if="selected.length" class="flex flex-wrap gap-1 mb-2">

    <u-badge
      v-for="(selection, index) in selected"
      :key="index"
      size="md"
      variant="subtle"
      color="charcoal"
    >

      <album-avatar v-if="props.contextType === 'album'" :album="selection" size="xs" />
      <tag-avatar v-else-if="props.contextType === 'tag'" :tag="selection" size="xs" />
      <people-avatar v-else-if="props.contextType === 'person'" :person="selection" size="xs" />
      <span class="font-semibold mr-1 ml-1.5 break-long-string">{{selection.name}}</span>

      <u-button
        :icon="COMMON_ICONS.close"
        size="xs"
        color="charcoal"
        variant="ghost"
        :padded="false"
        @click.stop="unselect(selection)"
      />
    </u-badge>
  </div>
</template>

<script setup>
  const props = defineProps({
    contextType: String,
  });

  const selected = defineModel('selected');

  function unselect(selection) {
    selected.value = selected.value.filter(s => s.id !== selection.id);
  }
</script>
