<!-- todo: refinement pass -->
<template>
  <div class="file-video-optimize-modal">

    <core-modal-header>Optimize Video?</core-modal-header>

    <core-modal-body>
      <p>Do you want to optimize this video to take up less storage space?</p>
      <ul class="text-sm mt-4">
        <li><strong>Current File Size:</strong> {{file.file_size_gb}}</li>
        <li><strong>Optimized File Size:</strong> {{file.transcoded_file_size_gb}} ({{savings}} reduction)</li>
      </ul>
      <u-alert
        color="heroblue"
        class="mt-4"
      >
        <template #description>
          This will replace your original uploaded version with the optimized version. This action cannot be undone. <a :href="EXTERNAL_URLS.helpCenterVideoFaq" target="_blank" rel="noopener">About Video Optimization.</a>
        </template>
      </u-alert>

    </core-modal-body>

    <core-modal-footer>

      <u-button @click="modal.close()" variant="soft" color="charcoal">Cancel</u-button>
      <u-button @click="optimize" color="charcoal" :loading="isBusy">Optimize Video</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    file: Object,
    modal: Object
  });

  const isBusy = ref(false);
  const savings = `${Math.round(((props.file.file_size - props.file.transcoded_file_size) /  props.file.file_size) * 100)}%`;

  async function optimize() {
    isBusy.value = true;
    try {
      const optimized = await useFileApi().optimizeVideoFile({fileId: props.file.id});
      emit('success', {optimized});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isBusy.value = false;
    }
  }

</script>
