<template>
  <section class="f-share_modal">

    <core-modal-header>{{headerText}}</core-modal-header>

    <core-modal-body>
      <core-share :share-object="shareObject" :share-type="shareType" />
    </core-modal-body>

  </section>
</template>

<script setup>
  const props = defineProps({
    shareType: {
      type: String,
      required: true
    },
    shareObject: {
      type: Object,
      required: true
    }
  });

  const headerText = computed(() => {
    const fileCount = props.shareObject?.files_count || 1;

    switch (props.shareType) {
      case 'tag':
      case 'album':
      case 'file':
        return `Share ${props.shareObject?.name}`;
      case 'profile':
        return 'Share Profile';
      case 'container':
        return `Share ${fileCount} File${fileCount > 1 ? 's' : ''}`;
    }
  });
</script>
