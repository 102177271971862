<template>
  <div class="border border-charcoal-200 bg-white flex justify-center overflow-hidden">
    <core-contextual-loading-box v-if="isLoading" key="loading" :loading-text="`Loading Groups`" size="sm" />
    <core-contextual-error-box v-else-if="isErrored" key="errored" :action="loadGroups" size="sm" />

    <div v-else class="w-full h-full flex flex-col">
      <core-add-new-to-list
        label="New Group"
        placeholder="Enter Group Name..."
        :add-fn="createGroup"
        @error="addNewGroupError"
      />

      <div class="flex-1 overflow-y-auto scrollbar-light" :class="isEmpty ? 'bg-white flex-center' : ''">
        <template v-if="isEmpty">
          <div class="p-5 text-center">
            <p class="font-serif text-xl">No Groups Available</p>
            <p class="text-sm text-charcoal-400">Groups are a great way to share your content with specific people. Create a group to get started.</p>
          </div>
        </template>
        <core-list-multi-selector v-else v-model="selectedGroups" :options="showOnlySelected ? selectedGroups.sort((a, b) => a.name.localeCompare(b.name)) : groups">
          <template #option-item="{option: group}">
            <div class="flex items-center gap-2 ">
              <groups-avatar :group="group" size="xs" />
              <span class="flex-1 text-sm break-long-string">{{group.name}}</span>
            </div>
          </template>
        </core-list-multi-selector>
      </div>

    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    showOnlySelected: Boolean
  })
  const selectedGroups = defineModel('selectedGroups');
  const isLoading = ref(true);
  const isErrored = ref(false);
  const groups = ref([]);
  const isEmpty = computed(() => !groups.value.length);

  async function loadGroups() {
    try {
      isLoading.value = true;
      const fetched = await useGroupsApi().fetchGroups();
      groups.value = fetched?.sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      isErrored.value = true;
    } finally {
      isLoading.value = false;
    }
  }

  async function createGroup(name) {
    const newGroup = await useGroupsApi().createGroup({name});
    groups.value.unshift(newGroup);
    selectedGroups.value = [newGroup, ...selectedGroups.value];
  }

  function addNewGroupError() {
    useErrorToast().add();
  }

  loadGroups();
</script>
