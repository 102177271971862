<template>
  <u-button variant="ghost" :size="size" :color="dark ? 'white' : 'charcoal'" :ui="ui">
    <slot />
  </u-button>
</template>

<script setup>
  const props = defineProps({
    size: {
      type: String,
      default: 'md'
    },
    dark: Boolean,
    iconColor: String
  });

  const ui = computed(() => {
    const base = {
      base: 'flex-col flex-center',
      size: {
        md: 'text-xs lg:text-sm whitespace-nowrap',
        lg: 'text-xs lg:text-sm whitespace-nowrap'
      },
      padding: {
        md: 'px-2 sm:px-2',
        lg: 'px-3 sm:px-3.5'
      }
    };


    if (props.iconColor) {
      base.icon = {
        base: `${props.iconColor}`
      };
    }

    if (props.dark) {
      return {
        ...base,
        color: {
          white: {
            ghost: 'text-white hover:bg-white/10 dark:hover:bg-white/15'
          }
        }
      };
    }

    return {
      ...base,
      color: {
        charcoal: {
          ghost: 'hover:bg-charcoal-200/60'
        }
      }
    };
  });
</script>
