
/**
 * @description
 * Gets a map of the lowest price for each print option
 *
 * @param product
 */
export function getLowestUnitPriceMap(product) {
  const map = {};

  product.options.forEach(option => {
    const ov = option.option_values[0];
    const biggestDiscount = ov.discounts.concat().sort((a, b) => b.minimum_quantity - a.minimum_quantity)[0].value;
    map[option.name] = ov.price_cents - biggestDiscount;
  });

  return map;
}

/**
 * @description
 * Given a batch, extracts all available sizes and finishes.
 *
 * @param product
 * @param batch
 * @returns {{sizes: any[], finishes: string[]}}
 */
export function getBatchPhotosetOptions(batch, product) {
  const lowestUnitPriceMap = getLowestUnitPriceMap(product);

  const options = batch.photo_sets.reduce((opts, photoset) => {
    opts.sizes[`${photoset.width}x${photoset.height}`] = {
      width: photoset.width,
      height: photoset.height,
      price: lowestUnitPriceMap[photoset.name]
    };
    opts.finishes[photoset.finish] = true;

    return opts;
  }, {sizes: {}, finishes: {}});

  return {
    sizes: Object.values(options.sizes),
    finishes: Object.keys(options.finishes).reverse() //lustre on top
  };
}
