<template>
  <u-popover :overlay="overlay" :ui="popoverConfig" :popper="{ arrow: true, placement: 'top' }" :disabled="!confirmWhen" @click="passThrough">
    <slot />

    <template #panel="{close}">
      <div class="p-3">
        <p v-html="confirmText" class="mb-3 text-sm" />
        <div class="grid grid-cols-2 gap-3">
          <u-button block :ui="{variant: {solid: `dark:text-white dark:bg-heroblue`}}" :color="color" @click="confirm(close)" :loading="isBusy">Confirm</u-button>
          <u-button block :ui="{variant: {soft: 'dark:text-white dark:bg-charcoal'}}" color="charcoal" variant="soft" @click="close">Cancel</u-button>
        </div>
      </div>

    </template>
  </u-popover>
</template>

<script setup>
  const isBusy = ref(false);

  const props = defineProps({
    overlay: Boolean,
    confirmText: String,
    confirmMethod: Function,
    color: String,
    confirmWhen: {
      default: true
    }
  });

  const popoverConfig = {
    wrapper: 'inline-block',
    width: 'w-[275px]',
    background: 'dark:bg-white',
    arrow: {
      background: 'dark:before:bg-white',
    }
  };

  async function confirm(close) {
    isBusy.value = true;
    await props.confirmMethod();
    isBusy.value = false;

    if (close) {
      close();
    }
  }

  function passThrough() {
    if (!props.confirmWhen) {
      confirm();
    }
  }
</script>
