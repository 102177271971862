<template>
  <div>

    <core-modal-header>Copy Tags?</core-modal-header>

    <core-modal-body>
      <p>Would you like to copy the tags from the <strong>selected {{files.length}} file(s)</strong>?</p>
    </core-modal-body>

    <core-modal-footer>
      <u-button variant="ghost" color="charcoal" @click="modal.close()">Cancel</u-button>
      <u-button variant="outline" @click="copy()">Files Only</u-button>
      <u-button @click="copy({shouldCopyTags: true})">Files & Tags</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    files: Array,
    userId: String,
    modal: Object
  });

  async function copy({shouldCopyTags} = {}) {
    try {
      await useFileApi().copyFiles({ids: props.files.map(f => f.id), userId: props.userId, shouldCopyTags});

      useSuccessToast().add({
        id: 'copy-files-success',
        description: `The ${props.files.length} selected file(s) were copied to your Gallery.`
      });

      emit('success');
    } catch (err) {
      if (err.response && err.response.code === 409) {
        useErrorToast().add({
          id: 'copy-files-not-enough-storage',
          description: 'Not enough storage to copy to your Gallery.'
        });
      } else {
        useErrorToast().add();
      }
    }
  }
</script>
