/**
 *
 * @param input - in cents
 * @param precision - decimal places
 * @param suffix - optional suffix - default is USD
 * @param showSuffix - turn off suffix
 * @param superSuffix - outputs suffix in sup tag - use vhtml for this
 * @param symbol - Currency symbol - default is '$'
 * @returns {string}
 */
export function formatCurrency(input, {precision = 2, suffix = 'USD', showSuffix = true, superSuffix = false, symbol = '$'} = {}) {
  if (input || input === 0) {
    let sfx = '';
    if (showSuffix) {
      sfx = superSuffix ? `<sup>${suffix}</sup>` :  ` ${suffix}`;
    }

    const value = parseFloat(input / 100).toFixed(precision);
    return `${symbol}${delimitNumber(value)}${sfx}`;
  }
}

export function delimitNumber(x, delimiter = ',') {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}
