<template>
  <div>
    <u-form :state="state" :schema="schema" @submit="copyProject" :validate-on="['submit']">
      <core-modal-header>
        <template v-if="isEditAsCopy">Edit Project</template>
        <template v-else>Copy Project</template>
      </core-modal-header>

      <core-modal-body>
        <p class="mb-3">
          <template v-if="isEditAsCopy">
            Because you've already ordered this project we'll create a copy for you to edit. You can edit the copy as much as you like without affecting the original.
          </template>
          <template v-else>
            This will create a copy of your project that includes your photos and text. You can edit the copy as much as you like without affecting the original.
          </template>
        </p>

        <u-form-group label="Title" name="title" required>
          <u-input placeholder="Title" v-model="state.name" autofocus />
        </u-form-group>

      </core-modal-body>

      <core-modal-footer>
        <u-button variant="ghost" color="charcoal" @click="modal.close()" v-if="!isCopying">Cancel</u-button>
        <u-button type="submit" :loading="isCopying">Copy</u-button>
      </core-modal-footer>

    </u-form>
  </div>
</template>

<script setup>
  import {object, string} from 'yup';

  const emit = defineEmits(['complete']);
  const props = defineProps({
    project: Object,
    isEditAsCopy: Boolean,
    modal: Object
  });

  const state = ref({
    name: getCopyName(props.project.name)
  });

  const schema = object({
    name: string().required('This field is required.')
  });

  const isCopying = ref(false);

  async function copyProject() {
    isCopying.value = true;

    try {
      const projectCopy = await useProjectsApi().copyProject({id: props.project.id, name: state.value.name});

      useSuccessToast().add();

      emit('complete', {projectCopy});
      props.modal.close();
    } catch (e) {
      useErrorToast().add();
    } finally {
      isCopying.value = false;
    }
  }

</script>
