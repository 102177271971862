<template>
  <u-popover :popper="{ arrow: true, placement: screenMd ? 'right' : 'bottom'}" :ui="popoverConfig" overlay>
    <u-button-group size="lg" :ui="{wrapper: {horizontal: 'flex w-full'}}">
      <u-button
        :label="dateRange?.displayText || 'Select a Date Range'"
        color="gray"
        variant="solid"
        block
        class="flex-1"
      />
      <u-button
        v-if="dateRange?.val"
        color="gray"
        :icon="COMMON_ICONS.close"
        variant="solid"
        @click.stop="clearRange"
      />
    </u-button-group>

    <template #panel="{close}">
      <core-date-range-picker-manager v-model="dateRange" :size="size" :year-range="yearRange" @close="close" />
    </template>
  </u-popover>
</template>

<script setup>
  import {useScreenSize} from '~/composables/core/screen-size.js';

  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1500, useDayjs().utc().year()]
    },
    size: {
      type: String,
      default: 'lg'
    }
  });

  const {md: screenMd} = useScreenSize();

  const dateRange = defineModel();

  const popoverConfig = {
    background: 'dark:bg-white',
    container: 'max-w-[90%] sm:max-w-unset',
    arrow: {
      background: 'dark:before:bg-white',
    }
  };

  dateRange.value = dateRange.value || {};

  function clearRange() {
    dateRange.value = {};
  }
</script>
